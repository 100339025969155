@import './variables.scss';

ayeq-root,
.cdk-overlay-container,
.cdk-drag-preview {
	color: $color-dark-grey;

	--mat-form-field-container-text-size: initial; // We want fields to take their font sizes from their parents
	--mat-select-trigger-text-size: initial;
	--mat-standard-button-toggle-label-text-size: initial;

	--mat-select-enabled-trigger-text-color: currentcolor; // We want the text color to inherit
	--mdc-outlined-text-field-input-text-color: currentcolor;
	--mat-option-selected-state-label-text-color: currentcolor;

	--mdc-protected-button-label-text-tracking: normal; // Not sure why this needs to be set as the default looks fine, but without this the letter spacing is large
	--mdc-filled-button-label-text-tracking: normal;
	--mdc-text-button-label-text-tracking: normal;
	--mat-select-trigger-text-tracking: normal;
	--mdc-list-list-item-label-text-tracking: normal;
	--mat-expansion-container-text-tracking: normal;
	--mdc-dialog-supporting-text-tracking: normal;

	--mat-sidenav-content-text-color: initial; // We want control over the content instead of it set to a particular color

	--mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.42);
	--mat-form-field-container-vertical-padding: 6px; // Default: 16px
	--mat-form-field-container-height: 36px; // Default: 56px. We want this minus the top and bottom differences from above 56 - (16-6)*2
	--mat-form-field-filled-with-label-container-padding-top: 16px;
	--mat-form-field-filled-with-label-container-padding-bottom: var(--mat-form-field-container-vertical-padding);
	--mdc-outlined-text-field-container-shape: 1px;
	--mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.15);
	--mdc-filled-text-field-container-color: transparent;

	.mat-mdc-tab-header {
		--mat-tab-header-label-text-tracking: normal; // Same as --mdc-protected-button-label-text-tracking but more specific
		--mat-tab-header-label-text-weight: bold;
	}

	.mdc-button:not(.mat-secondary):not(:disabled) {
		--mdc-filled-button-label-text-color: white;
		--mdc-protected-button-label-text-color: white;
	}

	.mat-mdc-checkbox {
		// Specificity needed
		--mat-checkbox-label-text-color: currentcolor; // We want the input label text color to inherit - specifically for checkboxes
		--mdc-checkbox-state-layer-size: 2rem;
	}

	.mat-mdc-standard-chip {
		// Needed for specificity
		--mdc-chip-label-text-font: inherit;
	}

	.mat-mdc-form-field-type-mat-mdc-select {
		min-height: 17px;
		.mat-mdc-select-trigger {
			vertical-align: middle;
		}
	}
	mat-form-field[appearance='outline']:not(.unfilled) .mat-mdc-text-field-wrapper {
		background: white;
	}
	.full-width {
		width: 100%;
		.mat-mdc-form-field-infix {
			width: auto;
		}
	}
	.mat-mdc-raised-button {
		border-radius: 1px;
	}
	.mat-mdc-button:hover .mat-button-focus-overlay {
		background-color: transparent;
	}
	.mat-button-toggle .mat-button-toggle-focus-overlay {
		border-bottom: 0 !important;
	}

	.mat-button-toggle-group.ng-invalid.ng-touched {
		border: 2px solid $color-invalid;
	}
	.mat-radio-group.ng-invalid.ng-touched .mat-radio-outer-circle {
		border-color: $color-invalid;
	}
	.mat-radio-group.ng-invalid.ng-touched .mat-radio-label-content {
		color: $color-invalid;
	}

	input.mat-input-element {
		line-height: 1em;
	}
	mat-mdc-select {
		line-height: 1em;
	}

	--mat-expansion-container-shape: 0px;
	--mat-expansion-header-text-color: #{$color-dark-grey};
	--mat-expansion-container-text-color: #{$color-dark-grey};

	mat-expansion-panel-header {
		mat-form-field {
			width: 75%;
			--mat-form-field-container-text-weight: bold;
			--mdc-filled-text-field-active-indicator-height: 0;

			&:hover,
			&:focus-within {
				background-color: white;
			}
		}
	}

	mat-expansion-panel-header.dark {
		--mat-expansion-header-text-color: white;
		--mat-expansion-header-indicator-color: white;
		--mat-expansion-header-hover-state-layer-color: #{$color-darkest-grey};
		&,
		&.mat-expanded:hover,
		&.mat-expanded:focus {
			background-color: $color-darkest-grey;
		}

		mat-form-field {
			--mdc-filled-text-field-caret-color: white;
			--mdc-filled-text-field-input-text-color: white;

			&:hover,
			&:focus-within {
				background-color: $color-dark-grey;
			}
		}
	}

	.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
		// The top they set was causing issues with us changing the size of the mat-form-field-container-height.
		// Would love to not hardcode, but need to reset to what's on .mdc-text-field .mdc-floating-label
		top: 50%;
	}

	.mat-mdc-mini-fab {
		box-shadow: none !important;
		color: white;
	}

	.mat-primary:disabled {
		cursor: not-allowed;
	}

	.ayeq-color.mat-mdc-flat-button.mat-primary,
	.ayeq-color.mat-mdc-raised-button.mat-primary,
	.ayeq-color.mat-mdc-fab.mat-primary,
	.ayeq-color.mat-mdc-mini-fab.mat-primary {
		background-color: var(--header-background-color, $color-ayeq-conversion);
		&:disabled {
			background-color: $color-ayeq-grey;
			color: white;
		}
	}

	.ayeq-blue.mat-mdc-flat-button.mat-primary,
	.ayeq-blue.mat-mdc-raised-button.mat-primary,
	.ayeq-blue.mat-mdc-fab.mat-primary,
	.ayeq-blue.mat-mdc-mini-fab.mat-primary {
		background-color: $color-ayeq-blue;
		&:disabled {
			background-color: $color-ayeq-grey;
			color: white;
		}
	}

	.ayeq-green.mat-mdc-flat-button.mat-primary,
	.ayeq-green.mat-mdc-raised-button.mat-primary,
	.ayeq-green.mat-mdc-fab.mat-primary,
	.ayeq-green.mat-mdc-mini-fab.mat-primary {
		background-color: $color-ayeq-green;
		&:disabled {
			background-color: $color-ayeq-grey;
			color: white;
		}
	}
	.ayeq-yellow.mat-mdc-flat-button.mat-primary,
	.ayeq-yellow.mat-mdc-raised-button.mat-primary,
	.ayeq-yellow.mat-mdc-fab.mat-primary,
	.ayeq-yellow.mat-mdc-mini-fab.mat-primary,
	.ayeq-yellow.mat-mdc-button.mat-primary {
		background-color: $color-ayeq-yellow;
		&:disabled {
			background-color: $color-ayeq-grey;
			color: white;
		}
	}

	.ayeq-yellow.mat-mdc-flat-button.mat-secondary,
	.ayeq-yellow.mat-mdc-raised-button.mat-secondary,
	.ayeq-yellow.mat-mdc-fab.mat-secondary,
	.ayeq-yellow.mat-mdc-mini-fab.mat-secondary,
	.ayeq-yellow.mat-mdc-button.mat-secondary,
	.ayeq-yellow.mat-mdc-checkbox {
		color: $color-ayeq-yellow;
	}
	.mat-tooltip {
		overflow-wrap: break-word;
	}

	.mat-mdc-mini-fab {
		box-shadow: none;
	}
	.cdk-overlay-pane:has(.mat-mdc-select-panel) {
		min-width: fit-content !important; // this is needed to override inline styles from the library
	}
	.mat-mdc-text-field-wrapper {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
	.no-appearance {
		--mdc-filled-text-field-active-indicator-height: 0px;
		.mdc-text-field--filled *::before,
		.mdc-text-field--filled *::after {
			border-bottom: none;
		}
	}
	.mdc-text-field--filled .mat-mdc-form-field-focus-overlay {
		background: none;
	}
}

.tree .root mat-form-field > .mat-form-field-wrapper,
.tree .leaf mat-form-field > .mat-form-field-wrapper {
	padding-bottom: 0;
	margin-bottom: 0;
}

mat-form-field.process-stage-field {
	.mat-mdc-text-field-wrapper {
		background-color: $color-light-grey-background;
	}
	&:focus-within {
		.mat-mdc-text-field-wrapper {
			background-color: white;
		}
	}
}

// https://github.com/angular/components/issues/26584
.mdc-evolution-chip-set .mat-mdc-standard-chip {
	.mdc-evolution-chip__cell--primary,
	.mdc-evolution-chip__action--primary,
	.mat-mdc-chip-action-label {
		overflow: hidden;
	}
}

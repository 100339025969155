@import './components/index.scss';
@import './variables.scss';
@import './material-overrides.scss';
@import './elements/index';
* {
	font-family: 'Open Sans', 'Helvetica Neue';
}

*,
::after,
::before {
	box-sizing: border-box;
}

:root {
	// Variables referenced by the TS code
	--large-breakpoint: #{map_get($grid-breakpoints, 'xl')};
	--min-stick-sidenav: #{map_get($container-max-widths, 'xl') - $sidebar-closed-width + $sidebar-max-opened-width};
}

body {
	height: 100vh;
	line-height: inherit;

	ayeq-layout .main {
		flex-grow: 1;
		padding-top: 1rem;
		padding-bottom: 1rem;
		background-color: $color-grey-border;
		min-width: $html-min-width - $sidebar-closed-width;
		color: $color-dark-grey;
		p {
			color: $color-dark-grey;
		}
		&:not(.new) {
			h1 {
				display: block;
				font-size: 2em;
				font-weight: bold;
				// undo reboot
				margin-top: 0.67em;
				margin-bottom: 0.67em;
				line-height: inherit;
			}
			h2 {
				// undo reboot
				margin-top: 0.83em;
				margin-bottom: 0.83em;
				line-height: inherit;
			}
			h3 {
				font-size: 1.17em;
				font-weight: bold;
				// undo reboot
				margin-top: 1em;
				margin-bottom: 1em;
				line-height: inherit;
			}
			h4 {
				font-size: 1rem;
				display: block;
				font-weight: bold;
				// undo reboot
				margin-top: 1.33em;
				margin-bottom: 1.33em;
				line-height: inherit;
			}
			h5 {
				display: block;
				font-size: 0.83em;
				font-weight: bold;
				// undo reboot
				margin: 10px 0;
				line-height: inherit;
			}
		}
	}
}

html {
	min-height: 100%;
	position: relative;
	min-width: $html-min-width;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

legend {
	float: inherit;
	width: auto;
}

p {
	margin-top: 1em;
	margin-bottom: 1em;
}

table {
	border-collapse: initial;
}

.navigation-container {
	width: 100%;
	margin-bottom: 35px;
}

@include media-breakpoint-up(xl) {
	.container {
		max-width: map_get($container-max-widths, 'xl') - $sidebar-closed-width !important;
	}
}

@include media-breakpoint-down(xl) {
	.container {
		max-width: map_get($container-max-widths, 'lg') - $sidebar-closed-width !important;
	}
}

// Remove the margins on print - including browser generated margin text
@media print {
	@page {
		size: auto;
		margin: 20mm;
	}
	body {
		margin-bottom: 0;
	}
}

.tooltip-allow-newlines {
	white-space: pre-line;
}

@counter-style chevron {
	system: cyclic;
	symbols: '\276F';
	suffix: ' ';
}

#gradient-line {
	height: 3px;
	background: #00a1e0; /* For browsers that do not support gradients */
	background: -webkit-linear-gradient(left, #00a1e0, #75b987, #d2d13c, #f8893f); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(right, #00a1e0, #75b987, #d2d13c, #f8893f); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(right, #00a1e0, #75b987, #d2d13c, #f8893f); /* For Firefox 3.6 to 15 */
	background: linear-gradient(to right, #00a1e0, #75b987, #d2d13c, #f8893f); /* Standard syntax (must be last) */
}

:placeholder-shown {
	text-overflow: ellipsis;
}

.well {
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	> .row:not(.well-ignore) {
		border-left: 1px solid $color-grey-border;
		border-right: 1px solid $color-grey-border;
	}
	> .row:first-child:not(.well-ignore) {
		border-top: 1px solid $color-grey-border;
	}
	> .row:last-child {
		border-bottom: 1px solid $color-grey-border;
	}
}

@import '../variables.scss';

.required-asterisk {
	color: $color-invalid;
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
}

.mat-checkbox.truncate {
	max-width: 100%;

	.mat-checkbox-layout {
		max-width: 100%;

		.mat-checkbox-label {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

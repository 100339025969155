@import '../variables.scss';
$circle-size: 20px;
$small-circle-size: 10px;

.subway-nav-header {
	height: 90px;
	.col {
		position: relative;
	}
	.bubble {
		height: 100%;
		padding-bottom: 10px;
		.name {
			display: flex;
			justify-content: center;
			height: 100%;
			margin-top: 50px;
		}
		&:hover {
			cursor: pointer;
		}
		&:not(:last-child) {
			&:after {
				content: '';
				border-bottom: 3px solid $color-lite-grey;
				height: 1px;
				width: calc(100% - #{$circle-size});
				position: absolute;
				top: 29px;
				right: calc(-50% + calc(#{$circle-size} / 2));
			}
		}
		&.complete {
			&:after {
				border-bottom: 3px solid $color-ayeq-green;
			}
			.circle {
				border: 3px solid $color-ayeq-green;
				background-color: $color-ayeq-light-green;
			}
		}
		&.active {
			.circle {
				height: 30px;
				width: 30px;
				top: 15px;
				border: 3px solid $color-ayeq-green;
				background-color: $color-ayeq-light-green;
				fa-icon {
					margin-left: 8px;
					margin-top: 6px;
				}
			}
		}
		.circle {
			height: $circle-size;
			width: $circle-size;
			position: absolute;
			margin-right: auto;
			margin-left: auto;
			top: 20px;
			right: 0px;
			left: 0px;
			border-radius: 50%;
			display: flex;
			background-color: $color-lite-grey;
			z-index: 2;
			fa-icon {
				color: $color-ayeq-green;
				font-size: 0.5em;
				margin-left: 3px;
				margin-top: 1px;
			}
		}
	}
}

.subway-nav-sidebar {
	width: 120px;
	height: 10px;
	margin-left: 15px;
	.col {
		position: relative;
	}
	.bubble {
		&:not(:last-child) {
			&:after {
				content: '';
				border-bottom: 3px solid $color-lite-grey;
				height: 1px;
				position: absolute;
				top: 9px;
				width: calc(100% - #{$small-circle-size});
				right: calc(-50% + calc(#{$small-circle-size} / 2));
			}
		}
		&.complete {
			&:after {
				border-bottom: 3px solid $color-ayeq-green;
			}
			.circle {
				border: 3px solid $color-ayeq-green;
				background-color: $color-ayeq-green;
			}
		}
		&.active {
			.circle {
				height: 30px;
				width: 30px;
				top: 15px;
				border: 3px solid $color-ayeq-green;
				background-color: $color-ayeq-light-green;
			}
		}
		.circle {
			height: $small-circle-size;
			width: $small-circle-size;
			position: absolute;
			margin-right: auto;
			margin-left: auto;
			top: 5px;
			right: 0px;
			left: 0px;
			border-radius: 50%;
			display: flex;
			background-color: $color-lite-grey;
			z-index: 2;
		}
	}
}

@use 'sass:math';
@import '../variables.scss';

$header-padding-lr: map-get($spacers, 3);
$trunk-margin-l: $header-padding-lr;
$trunk-padding-l: 4rem;

$body-padding-bottom: 1rem;
$header-height-collapsed: 3rem;
$header-height-expanded: 4rem;
$branch-indent: 0.4rem;
$branch-size: 1px;
$branch-gap: 0.2rem;
$branch-button-margin-top: 0.5rem;
$color-light-grey-background_no-alpha: rgb(250, 250, 250);

@mixin branch {
	content: '';
	display: inline-block;
	position: absolute;
	left: $branch-indent - $trunk-padding-l;
	width: $trunk-padding-l - $branch-indent - $branch-gap;
	height: $branch-size;
	background-color: $color-grey-border;
	// copied from .mat-expansion-panel to match its expansion
	transition: top 225ms cubic-bezier(0.4, 0, 0.2, 1);
}

@mixin last-branch {
	position: relative;

	&.expanded::after {
		top: calc(#{math.div($header-height-expanded, 2)});
		height: calc(100% - #{math.div($header-height-expanded, 2)});
	}

	&::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: calc(#{math.div($header-height-collapsed, 2)});
		left: $branch-indent - $trunk-padding-l;
		width: $branch-size;
		height: calc(100% - #{math.div($header-height-collapsed, 2)});
		background-color: $color-light-grey-background_no-alpha;
		transition: top 225ms cubic-bezier(0.4, 0, 0.2, 1);
	}
}

.trunk {
	position: relative;
	margin-left: $trunk-margin-l;
	padding-left: $trunk-padding-l;

	&::before {
		content: '';
		display: inline-block;
		position: absolute;
		top: 0;
		left: $branch-indent;
		width: $branch-size;
		height: calc(100% - #{$body-padding-bottom});
		background-color: $color-grey-border;
	}

	.branch {
		position: relative;

		&::before {
			@include branch;
			top: calc(#{math.div($header-height-collapsed, 2)} - #{$branch-size});
		}

		&.expanded::before {
			top: calc(#{math.div($header-height-expanded, 2)} - #{$branch-size});
		}
	}

	.last-branch {
		@include last-branch;

		&.has-button {
			margin-top: $branch-button-margin-top;
			&::before,
			&::after {
				top: 50%;
			}
		}
	}
}

@media print {
	.trunk .last-branch-print {
		.branch,
		&.branch {
			@include last-branch;
		}
	}
}

@use 'sass:math';
@import '../variables.scss';

.close-icon,
.edit-icon {
	color: $color-grey-icons;
	cursor: pointer;
	margin-right: 5px;
	margin-left: 10px;
	padding: 4px;
	position: relative;

	&:not(.show) {
		visibility: hidden;
	}
}

mat-expansion-panel-header {
	&:hover {
		.close-icon,
		.edit-icon {
			visibility: visible;
		}
	}
}

.deleteable-row {
	&:hover {
		.close-icon {
			visibility: visible;
		}
	}
}

.disappearing-input:not(.error) {
	--mdc-outlined-text-field-outline-width: 0px;
	.mat-form-field-appearance-outline .mat-form-field-outline {
		opacity: 0;
	}

	&:hover {
		--mdc-outlined-text-field-outline-width: 1px;
		.close-icon {
			visibility: visible;
		}
		.mat-form-field-appearance-outline .mat-form-field-outline {
			opacity: initial;
		}
	}

	mat-form-field {
		background: none;
		.mat-mdc-select-arrow-wrapper {
			visibility: hidden;
		}

		:hover,
		:focus,
		:focus-within,
		&.mat-focused {
			background: white;
			.mat-mdc-select-arrow-wrapper {
				visibility: initial;
			}
		}
		&.mat-focused .mat-form-field-outline {
			opacity: initial;
		}
	}
}

$firstLeafMargin: 10px;
.tree {
	font-size: 14px;
	align-items: center;

	.root {
		z-index: 1;

		&.view-only {
			padding: 1rem 0.5rem;
		}
	}
	.limb {
		padding-left: 50px;
		.leaf {
			padding: $firstLeafMargin 0;
			position: relative;
			display: flex;
			align-items: center;
			&:before {
				content: '';
				border-left: 2px solid $color-grey-border;
				height: 150%;
				position: absolute;
				left: -35px;
			}
			&:first-child {
				padding-top: $firstLeafMargin;
				&:before {
					margin-top: math.div($firstLeafMargin, -2);
					height: calc(100% + #{$firstLeafMargin});
					top: math.div($firstLeafMargin, 2);
				}
			}
			&:last-child:before {
				height: 50%;
				bottom: 50%;
			}
			&:not(.less):after {
				content: '';
				border-bottom: 2px solid $color-grey-border;
				width: 35px;
				position: absolute;
				left: -35px;
				bottom: 50%;
			}
			.row {
				width: 100%;
			}
			&.view-only {
				height: 3rem;
				padding-bottom: 0.25rem;
				padding-left: 0.75rem;
			}
		}
		.mat-mini-fab {
			margin-top: 1rem;
			margin-bottom: 1rem;
			box-shadow: none;
		}
		.mat-mdc-form-field {
			flex: 1;
		}
	}
}

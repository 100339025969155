@import '../variables.scss';
.alert {
	margin-top: 1.5rem;
	padding: 0.25rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	&.alert-error {
		background-color: $color-error-background;
		border-color: $color-error-border;
		color: $color-error-text;
	}

	&:empty {
		display: none;
	}
}

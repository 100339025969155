@import '../variables.scss';

$cell-padding: 10px;

table {
	width: 100%;
	td,
	th {
		padding: $cell-padding;

		// no-wrap with ellipsis derived from https://stackoverflow.com/a/30362531/1604460
		&.no-wrap {
			position: relative;
		}
		&.no-wrap:before {
			content: 'non-empty'; // must be anything non-empty
			visibility: hidden;
		}
		&.no-wrap span {
			position: absolute;
			left: $cell-padding;
			right: $cell-padding;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	th {
		font-weight: bold;
	}
	&.bordered {
		border-collapse: collapse;
		&,
		td,
		th {
			border: 1px solid $color-grey-border;
		}
	}
	&.bordered-row {
		border-collapse: collapse;
		tr:not(:first-child) {
			td,
			th {
				border-top: 1px solid $color-grey-border;
			}
		}
	}
}

table.quarter-table {
	width: 100%;
	padding: 0;
	margin: 15px 0;
	border: 1px solid $color-grey-border;
	border-collapse: collapse;

	th {
		font-weight: bold;
		color: white;
		background-color: $color-ayeq-green;

		&.table-label {
			background-color: $color-ayeq-dark-green;
		}
	}

	th,
	td {
		text-align: center;
		padding: 10px 15px;

		&.table-label {
			text-align: left;
		}

		&:not(:first-child) {
			border-left: 1px solid $color-grey-border;
		}

		&:first-child {
			width: 45%;
		}
	}

	tr {
		background-color: $color-light-grey-background;
		&:nth-child(even) {
			background-color: white;
		}
	}
}

@import '../variables.scss';
.container {
	.bottom-nav {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin: 1rem 0;
		.nav-text {
			font-size: 12px;
			font-weight: bold;
			margin-right: 15px;
			width: fit-content;
		}
		.back-nav {
			margin-right: auto;
		}
		.done {
			background-color: $color-dark-grey;
		}
	}
}

.clickable {
	&,
	& * {
		cursor: pointer;
	}
}
.grabable {
	&,
	& * {
		cursor: grab;
	}
}

.colored-bg {
	background-color: var(--header-background-color, $color-ayeq-conversion);
	color: white;
}
.colored-text {
	color: var(--colored-text-color, $color-ayeq-conversion);
}

// Psuedo-Bootstrap options
.d-list-item {
	display: list-item;
	margin-left: 16px; // This overrides Bootstrap killing of the margin that keeps bullets from displaying
}
.flex-1 {
	flex: 1;
}
.font-smaller {
	font-size: smaller;
}
.fw-semibold {
	font-weight: 600;
}

@import '../variables.scss';

#ayeq-body {
	.mat-mdc-dialog-container {
		--mat-dialog-actions-padding: 16px 24px;
		position: relative;
		fa-icon.close-button {
			position: absolute;
			right: 12px;
			top: 5px;
			font-size: 20px;
			cursor: pointer;
			color: $color-ayeq-grey;
		}
		.ayeq-dialog-container {
			max-height: calc(100vh - 85px);
		}
	}
}

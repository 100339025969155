html {
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;
}
@import 'variables';
@import '../../node_modules/bootstrap/scss/functions';

@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/mixins';

@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/containers';
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/utilities/api';
@import '../../node_modules/bootstrap/scss/grid';

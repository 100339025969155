@import '../variables.scss';

mat-form-field.mat-form-field-appearance-outline {
	&.ng-invalid .mat-form-field-outline {
		color: $color-invalid;
	}

	&.state-success .mat-form-field-outline,
	&.state-success.mat-focused .mat-form-field-outline {
		color: $color-success;
	}
}

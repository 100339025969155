@import '../variables.scss';
.layout-container {
	position: relative;
}

h2 {
	color: $color-ayeq-grey;
	text-align: center;
	font-size: 35px;
	font-weight: normal;
	.subtitle {
		color: $color-dark-grey;
		font-size: 0.5em;
	}
}

.divider {
	border-bottom: 2px solid $color-grey-border;
	bottom: 0px;
	width: 100%;
}

@import '../variables.scss';

.empty-state {
	display: flex;
	align-self: center;
	align-items: center;
	text-align: center;
	flex-grow: 1;
	color: $empty-state-color;
}

.overflow-single {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.overflow-multi {
	display: inline-block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

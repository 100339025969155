@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins/breakpoints';

// Bootstrap overrides
$legend-font-size: 1rem;

// Colors
$color-lite-grey: #ccc;
$color-dark-grey: #666;
$color-darkest-grey: #4b4a4a;
$color-ayeq-grey: #949494;
$color-ayeq-green: #51ad98;
$color-ayeq-light-green: #d4e9e4;
$color-ayeq-dark-green: #348975;
$color-ayeq-darkest-green: #2f685a;
$color-ayeq-blue: #00a1e0;
$color-ayeq-dark-blue: #036b93;
$color-ayeq-light-blue: #a0d6ed;
$color-ayeq-light-yellow: #f3f1c7;
$color-ayeq-yellow: #d0cc16;
$color-ayeq-dark-yellow: #a29f12;
$color-ayeq-light-orange: #f9dcc7;
$color-ayeq-orange: #fe893c;
$color-ayeq-dark-orange: #c7692a;
$color-ayeq-blue-grey: #144559;
$color-ayeq-light-blue-grey: #9dc9db;
$color-grey-icons: #999999;

$color-primary: $color-ayeq-yellow;
$color-accent: $color-ayeq-blue;
$color-invalid: #be5f42; // important to match the material theme (warn-800)
$color-error-background: #f2dede;
$color-error-border: #ebcccc;
$color-error-text: #a94442;
$color-success: green;
$color-grey-border: #e7e9ec;
$color-light-grey-background: #f9f9f9;
$color-grey-sidenav: rgba(148, 148, 148, 0.1);
$color-light-green-background: #e5f4f1;
$color-light-yellow-header: rgb(255, 255, 247);
$color-light-blue-header: rgb(247, 255, 255);

$disabled-background: #e6e7dd;
$disabled-color: #b4bdbf;

$empty-state-color: $color-ayeq-grey;
$text-grey: #c5c5c5;
$text-white: #fefefe;

$input-hint-height: 12px;
$footer-height: 42px;

$color-ayeq-strategy: $color-ayeq-blue;
$color-ayeq-conversion: $color-ayeq-green;
$color-ayeq-execution: $color-ayeq-yellow;
$color-ayeq-analytics: $color-ayeq-orange;
$color-ayeq-settings: $color-ayeq-blue-grey;

$color-ayeq-light-strategy: $color-ayeq-light-blue;
$color-ayeq-light-conversion: $color-ayeq-light-green;
$color-ayeq-light-execution: $color-ayeq-light-yellow;
$color-ayeq-light-analytics: $color-ayeq-light-orange;
$color-ayeq-light-settings: $color-ayeq-light-blue-grey;

$color-ayeq-column-headers-1: #87c1b3;
$color-ayeq-column-headers-2: #73b0a1;
$color-ayeq-column-headers-3: #589888;
$color-ayeq-column-headers-4: #3f7769;

$color-ayeq-completed: $color-ayeq-light-blue;
$color-ayeq-on-track: #88cbbe;
$color-ayeq-at-risk: #e8e586;
$color-ayeq-off-track: #faa56e;
$color-ayeq-abandoned: $color-error-text;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
	(
		0: 0,
		1: (
			$spacer * 0.25,
		),
		2: (
			$spacer * 0.5,
		),
		3: $spacer,
		4: (
			$spacer * 1.5,
		),
		5: (
			$spacer * 3,
		),
	),
	$spacers
);

$dialog-title-margin: -24px -24px 20px -24px;
$dialog-title-padding: 25px 25px 20px 25px;

$html-min-width: map-get($grid-breakpoints, lg);
$sidebar-closed-width: 60px;
$sidebar-max-opened-width: map-get($grid-breakpoints, xl) - map-get($grid-breakpoints, lg) + $sidebar-closed-width;

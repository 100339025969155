@import '../variables.scss';
.tab-row {
	position: relative;
	height: 90px;
	display: flex;
	justify-content: space-around;
}

.tab {
	display: flex;
	flex-direction: column;
	justify-content: center;

	cursor: pointer;
	padding-top: 30px;
	padding-right: 0px;
	padding-left: 0px;
	position: relative;
	.tab-title,
	.tab-subtitle {
		text-align: center;
	}
	.tab-subtitle {
		font-size: 0.8em;
	}
	.tab-title {
		padding-bottom: 3px;
	}
	.bottom-line {
		position: absolute;
		width: 75%;
		bottom: -1px;
		left: 0px;
		right: 0px;
		margin-left: auto;
		margin-right: auto;
	}
	&:hover {
		&:not(.active) .bottom-line {
			opacity: 0.5;
		}
	}
	&.active {
		.tab-title {
			color: $color-dark-grey;
		}
	}
}
